import { TCountryCode } from 'countries-list';
import { AnswerValue } from 'types/';

export interface CommitmentInitiatePayload {
  commitment_option_id: string;
}

export interface CommitmentNextActionPayload {
  id: string;
}

export interface CommitmentResultPayload {
  url: string;
}

export interface ProfilerQuestionPayload {
  id?: string;
}

export interface CommitmentAnswerPayload {
  id: string;
  profiler_id: number;
  answer: AnswerValue;
  other?: string;
  verisoul_session_id?: string;
}

export interface SupplierLogInPayload {
  url: string;
  verisoul_session_id?: string;
}

export interface SupplierSignUpPayload extends SupplierLogInPayload {
  agree_termsofservice: boolean;
  agree_privacypolicy: boolean;
  agree_cookiepolicy: boolean;
}

export enum SupplierStatsDateGrouping {
  // TODO: uncomment other values when BE will be ready
  // Second = 'second',
  // Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  // Quarter = 'quarter',
  // Year = 'year',
}

export interface SupplierStatsQueryParams {
  /**
   * @format ISO 8601 date string in UTC timezone
   * @example 2021-09-01T00:00:00
   */
  from?: string;
  /**
   * @format ISO 8601 date string in UTC timezone
   * @example 2021-09-30T23:59:59
   */
  until?: string;
  date_grouping?: SupplierStatsDateGrouping;
  country_grouping?: boolean;
  country_codes?: TCountryCode[];
  page: number;
  page_size: number;
}

export interface SupplierTransactionsQueryParams {
  /**
   * @format ISO 8601 date string in UTC timezone
   * @example 2021-09-01T00:00:00
   */
  from?: string;
  /**
   * @format ISO 8601 date string in UTC timezone
   * @example 2021-09-30T23:59:59
   */
  until?: string;
  country_codes?: TCountryCode[];
  mid?: string;
  pid?: string;
  page: number;
  page_size: number;
}

export interface SupplierStatsResultsPayload {
  date_group: string;
  supplier_id: string | number;
  entries: number;
  completes: number;
  conversion: number;
  throttled: number;
  terminated: number;
  blacklisted: number;
  earnings: number;
  reversals: number;
  epc: number;
  cpi: number;
  loi: number | string;
  drops: number;
  median_commitment_duration: string | null;
  median_commitment_duration2: string | null;
  mean_commitment_duration: string | number;
  mean_loi: string | number;
  loi2: string | number;
}
export interface SupplierStatsPayload {
  pagination: {
    current: number;
    has_next: boolean;
    has_previous: boolean;
    count: number;
    num_pages: number;
    page_size: number;
  };
  result: SupplierStatsResultsPayload[];
}

export interface SupplierTransactionsResultsPayload {
  id: number;
  datetime_started: string;
  datetime_updated: string;
  pid: string;
  mid: string;
  cpi: number;
  outcome_label: string | null;
  country: string;
}
export interface SupplierTransactionsPayload {
  pagination: {
    current: number;
    has_next: boolean;
    has_previous: boolean;
    count: number;
    num_pages: number;
    page_size: number;
  };
  result: SupplierTransactionsResultsPayload[];
}

export interface SupplierUrlsPayload {
  redirects?: {
    complete?: string;
    term?: string;
    oq?: string;
    blacklist?: string;
  };
  s2s?: {
    complete?: string;
    reversal?: string;
    term?: string;
    oq?: string;
    blacklist?: string;
  };
}

export interface SupplierUrlsParams {
  redirects: {
    complete: string;
    term: string;
    oq: string;
    blacklist: string;
  };
  s2s: {
    complete: string;
    reversal: string;
    method: string;
  };
}
